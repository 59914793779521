body {
  overflow-x: hidden;
  font-weight: 400;
}
#root{
  max-width: 100%;
}
.custom-dropdown {
  position: absolute;
  top: 50px; /* Adjust this based on your design */
  right: 120px;
  background-color: white;
  border: 1px solid rgb(227, 229, 232);
  padding: 20px 50px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  color: black;
}

.custom-dropdown.custom-dropdown-solution {
  position: absolute;
  top: 50px; /* Adjust this based on your design */
  right: 380px;
  background-color: white;
  border: 1px solid rgb(227, 229, 232);
  padding: 20px 50px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  color: black;
}

.custom-dropdown li:hover{
  background-color: lightgrey;
}


/* HamburgerButton.css */
.hamburger-button {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 20px;
  height: 2px;
  background-color: #333;
  margin: 3px 0;
  transition: 0.4s;
}

/* Open state styling */
.hamburger-button.open .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.hamburger-button.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger-button.open .bar:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

/* HeroSection.css */
.section-container {
  text-align: left;
  background-color: #ffffff;
  padding: 3% 10%;
  padding-bottom: 60px;
}

.main-heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 600;
  color: black;
}

.sub-heading {
  font-size: 1rem;
  font-weight: 400;
  color: black;
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
}
.content {
  display: flex;
}

.text-content {
  flex: 1;
}

.image-content {
  flex: 1;
}

@media (max-width:600px) {
  .content{
    flex-direction: column;
    gap: 20px;
  }
}

.centered-button {
  background-color: #000; /* Example background color */
  color: #fff;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 15px;
  transition: background-color 0.3s ease; /* Add a smooth transition effect */
}

.centered-button:hover {
  background-color: white; /* Change background color on hover */
  border: 1px solid black;
  color: black;
}

.centered-button-inverted {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  background-color: white; /* Change background color on hover */
  border: 1px solid black;
  color: black;
  cursor: pointer;
  border-radius: 15px;
  transition: background-color 0.3s ease; /* Add a smooth transition effect */
}

.centered-button-inverted:hover {
  background-color: black; /* Example background color */
  color: #fff;
  border: 1px solid black;
}
@media (max-width:500px) {
  .button-container{
    flex-direction: column;
  }
  .centered-button{
    width: 100%;
  }
  .centered-button-inverted{
    width: 100%;
  }
}

.cardContainer {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  height: 280px;
  gap: 20px;
  overflow-x: auto; /* Allow horizontal scrolling if cards overflow */
  padding-left: 5%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.cardContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.cardContainer {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.card {
  flex: 0 0 auto; /* Allow cards to shrink if necessary */
  width: 200px;
  height: 250px;
  overflow: hidden;
  position: relative;
  transition: width 0.4s;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card:hover {
  width: 320px; /* Expand the card on hover */
}

.card-content {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-color: white;
  border: 1px solid black;
  color: black;
  padding: 20px;
  box-sizing: border-box;
}

.card-content h2 {
  margin-bottom: 2px;
}

.card-content p {
  display: none;
}

.card:hover .buttons {
  display: block;
}

.card:hover .buttons button {
  margin: 5px;
}

.card:hover .card-content p {
  display: block;
}


.page-container {
  display: flex;
  flex-direction: column;
}

.left-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  align-items: center;
  padding: 0px 2%;
  justify-content: flex-start;
  background-color: #343483;
}

.left-section h1{
  margin-top: 10px;
}
.joinButton{
  color: #fff;
  background-color: #343483;
  border: 1px solid white;
  margin-top: 5px;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 1rem;
}

.joinButton:hover {
  color: #4b0082;
  background-color: white;
}


.left-section img {
  max-width: 100%;
  height: auto;
  border-radius: 40px;
  margin-bottom: 20px;
}

.right-section {
  flex: 1;
  max-width: 100%;
  padding: 20px;
  background-color: #343483;
}
.right-section h2{
  margin-top: 10px;
  margin-bottom: 10px;
}



.card1 {
  background-color: #444398;
  padding: 20px;
  color: white;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.card:hover {
  transform: scale(1.05);
}

/* Media Query for Mobile View */
@media (min-width: 600px) {
  .page-container {
    flex-direction: row; /* Switch back to row layout for wider screens */
  }

  
}

.sectionFooter {
  display: flex;
  color: white;
  background-color: rgb(26, 26, 26);
  justify-content: space-between;
  padding-top: 50px;
  flex-wrap: wrap;
}

.componentIcon {
  width: 30%;
}

.componentIcon img{
  margin-left: 60px;
  margin-bottom: 10px;
}
.componentIcon p{
  padding-left: 60px;
  margin-bottom: 20px;
  color: #8e8e8e;
}
.componentIcon div{
  padding-left: 50px;
  margin-top: 5px;
}
.componentIcon svg {
  margin: 5px;
  color: #8e8e8e;
  padding: 10px;
  font-size: 24px;
  border-radius: 30%;
  border: 1px solid #8e8e8e;
  background-color: rgb(26, 26, 26);
}
.componentIcon svg:hover {
  color: #B1FC03;
  border: 1px solid #B1FC03;
}

.componentFooter {
  width: 20%;
  margin-bottom: 20px;
  text-align: center;
}

.componentFooter ul li a {
  color: white;
  text-decoration: none;
}

.componentFooter ul li a:hover {
  color: #8e8e8e;
}

@media (max-width: 768px) {
  .sectionFooter {
    flex-direction: column;
  }

  .componentFooter {
    width: 100%;
    text-align: center;
  }

  .componentIcon {
    width: 100%;
    text-align: left;
  }
  .componentIcon img{
    margin-left: 25px;
    margin-bottom: 10px;
  }
  .componentIcon p{
    padding-left: 25px;
    color: #8e8e8e;
    margin-bottom: 20px;
  }
  .componentIcon div{
    padding-left: 20px;
    margin-top: 5px;
    margin-bottom: 50px;
  }
}

/* CardSection.css */

.card-section {
  padding: 20px; /* Adjust padding as needed */
  padding-bottom: 50px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card2 {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 10px;
  background-color: #fff;
}

.card-image {
  flex: 1;
  height: auto;
  border-radius: 10px 0 0 10px;
}

.card-content1 {
  flex: 2;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  padding: 15px;
  gap: 10px;
  box-sizing: border-box;
  min-height: 200px;
}

.card-content1 h2, p {
  margin: 0;
}

@media (max-width: 500px){
  .card2{
    flex-direction: column;
  }
}

#scroller1 {
  background-color: #B1FC03;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  z-index: 10;
}
#scroller1::-webkit-scrollbar {
  display: none;
}
#scroller-in1 {
  display: inline-block;
  white-space: nowrap;
  animation-name: scroll;
  animation-duration: 120s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

#scroller1 h4 {
  display: inline-block;
  font-size: 20px;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: all linear 1s;
  color: black;
}
.centreAlign{
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 10px;
}

.centreAlign svg{
  height: 35px;
  width: 35px;
}
#scroller1 h4:hover {
  color: black;
  -webkit-text-stroke: 1px #000;
}
#scroller {
  /* background-color: red; */
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  z-index: 10;
}
#scroller::-webkit-scrollbar {
  display: none;
}
#scroller-in {
  display: inline-block;
  white-space: nowrap;
  animation-name: scroll;
  animation-duration: 300s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
#scrollerReverse-in {
  display: inline-block;
  white-space: nowrap;
  animation-name: scrollReverse;
  animation-duration: 300s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

#scroller h4 {
  display: inline-block;
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 20px;
  transition: all linear 1s;
  color: #8E8E8E;
}
.centreAlign{
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 10px;
}

.centreAlign svg{
  height: 35px;
  width: 35px;
}
#scroller h4:hover {
  color: black;
  -webkit-text-stroke: 1px #000;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes scrollReverse {
  from {
    transform: translateX(-90%);
  }
  to {
    transform: translateX(0);
  }
}

.container {
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
}

.left-section1 {
  width: 100%; /* Adjust as needed */
  margin-bottom: 100px;
  position: static;
  padding-right: 20px;
}

@media (max-width: 500px){
  .container{
    flex-wrap: wrap
  }
}
.right-section1 {
  width: 100%;
}

@media (min-width: 768px) {
  .left-section1 {
    width: 50%;
  }

  .stickySection{
    position: sticky;
    top: 10px;
  }

  .right-section1 {
    width: 50%;
  }
}

.section {
  display: flex;
  padding: 10px;
  padding-left: 5%;
  padding-right: 5%;
  transition: background-color 0.3s ease;
}

.topic {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  transition: background-color 0.5s ease;
  font-size: 20px;
  font-weight: 550;
}

.topic:hover {
  background-color: #f1f8e9;
}

.topic.active {
  background-color: #444398;
  color: #000;
  padding: 20px;
  padding-right: 30px;
}

.right-column {
  flex: 2;
  width: 50%;
  transition: background-color 0.3s ease;
}

.description {
  background-color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
  min-height: 300px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.topic.active + .description {
  background-color: #ecf0f1;
}

@media (max-width: 767px) {
  .section {
    flex-direction: column;
  }

  .right-column {
    width: 100%;
  }
}

.left-column {
  flex: 1;
  background-color: white;
  padding: 10px;
  transition: background-color 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.topic-container {
  position: relative;
}

.topic-description {
  display: block;
  transition: opacity 0.3s ease, max-height 0.3s ease;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  font-weight: 300;
  font-size: 16px;
  margin-top: 10px;
}

.topic.active .topic-description {
  opacity: 1;
  max-height: 150px; /* Set your desired max height */
  transition: opacity 0.3s ease, max-height 0.3s ease;
}


/* styles.css */

/* DarkSection styles */
.DarkSection {
  min-height: 60vh;
  background-color: rgb(26, 26, 26);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
}

.DarkSection .left-content {
  width: 60%;
  color: white;
  margin-bottom: 50px;
}

.DarkSection h2 {
  font-size: 3rem;
  font-weight: bold;
}

.DarkSection p {
  font-size: 1.5rem;
  color: #8E8E8E;
  margin-bottom: 20px;
}

.DarkSection .left-content button {
  background-color: #B1FC03;
  color: black;
  padding: 12px 24px;
  font-size: 1rem;
  border: none;
  border-radius: 15px;
  cursor: pointer;
}

.DarkSection .right-content {
  margin-left: 20px;
  border-radius: 20px;
  background-color: #B1FC03;
  color: black;
  padding: 30px;
  width: 25%;
}

.DarkSection .right-content h3 {
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 10px;
}

.DarkSection form div {
  margin-bottom: 10px;
}

.DarkSection input {
  width: 100%;
  padding: 15px;
  border: 1px solid black;
  border-radius: 10px;
  box-sizing: border-box;
}

.DarkSection input::placeholder{
  color: #000;
}

@media (max-width:500px) {
  .DarkSection{
    flex-direction: column;
    padding: 10px;
  }
  .DarkSection .right-content{
    width: 70%;
    margin-bottom: 50px;
    margin-left: 0;
  }
  .DarkSection .left-content{
    width: 80%;
  }
}
.faq-section {
  width: 100%;
  margin: auto;
}

@media (min-width:700px) {
  .faq-section{
    width: 60%;
  }
}

.faq-item {
  border: 1px solid #ddd;
  margin-bottom: 10px;
  border-radius: 15px;
  padding: 5px;
  overflow: hidden;
  transition: height 0.3s ease; /* Add transition property */
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-weight: 600;
  background-color: #fff;
  cursor: pointer;
}

.question-content {
  flex: 1;
}

.faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease, max-height 0.3s ease; /* Add transition properties */
}

.faq-item.open {
  height: auto; /* Auto height for the open state */
}

.faq-item.open .faq-answer {
  opacity: 1;
  max-height: 200px; /* Set a sufficiently large max-height for smooth expansion */
}

.faq-item:hover{
  background-color: #f1f8e9;
}
.faq-item:hover .faq-question{
  background-color: #f1f8e9;
}


.toggle-button {
  color: rgb(56,56,56);
  border: none;
  background-color: transparent;
  font-size: 16px;
  transition: transform 0.3s ease;
}

.rotate {
  transform: rotate(45deg);
}


/* style.css */

.cardContainerUsp {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.cardUsp {
  width: 30%;
  min-height: 475px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border: 1px solid lightgray;
}

@media (max-width:786px) {
  .cardContainerUsp{
    flex-direction: column;
  }
  .cardUsp{
    width: 90%;
  }
}

.cardUsp img {
  width: 96%;
  height: 250px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: 2%; /* Space between image and card border */
}

.cardContentUsp {
  padding: 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
}

.cardTitleUsp {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: black;
}

.cardDescriptionUsp {
  color: black;
}

/* styles.css */

.call-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 50px;
  background-color: white;
  justify-content: center;
  padding-bottom: 70px;
}

.call-item {
  width: 20%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.call-item p{
  font-size: 20px;
  color: #383838;
}

@media (max-width: 786px) {
  .call-item {
    width: 80%;
    padding-left: 30px;
    flex-direction: column;
  }
  .call-section{
    gap: 2px;
    justify-content: flex-start;
  }
}
.AboveFooter{
  padding-left: 20%;
  padding-right: 20%;
}
@media (max-width:700px) {
  .AboveFooter{
    padding-left: 10%;
    padding-right: 10%;
  }
}
.chips {
  padding: 8px 12px;
  margin: 4px auto;
  font-size: 1rem;
  overflow: hidden;
  border-radius: 18px;
  width: max-content;
  background-color: white;
  color: black;
  border: 1px solid #ccc;
}

/* Styling for the section heading */
.section-heading {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  margin: 20px 0;
}

/* Styling for the section text */
.section-text {
  text-align: center;
  font-size: 20px;
  margin-bottom: 50px;
  font-weight: 400;
  color: #383838;
}

/* Media query for mobile view */
@media (max-width: 500px) {
  /* Align text to the left for mobile view */
  .section-text {
    text-align: left;
  }
  .section-heading{
    text-align: left;
  }
  .chips{
    margin: 4px 5px;
  }
}

.blog-post {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  font-size: 24px;
  margin-bottom: 10px;
}

.meta-info {
  color: #888;
}

.divider {
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
  border-top: 1px solid #ccc;
}

.post-image {
  width: 100%;
  height: 600px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.office365-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.office365-container h1 {
  color: #333;
}

.office365-container h2 {
  color: #666;
}

.office365-container h3 {
  color: #999;
}

.office365-container p {
  margin-bottom: 15px;
}

.office365-container ul {
  margin-bottom: 15px;
}

.office365-container li {
  margin-bottom: 5px;
}

.powerapps-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.powerapps-container h1 {
  color: #333;
}

.powerapps-container h2 {
  color: #666;
}

.powerapps-container p {
  margin-bottom: 15px;
}

.powerapps-container ul {
  margin-bottom: 15px;
}

.powerapps-container ol {
  margin-bottom: 15px;
  padding-left: 20px;
}

.powerapps-container li {
  margin-bottom: 5px;
}
.sharepoint-container {
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.fullwidth-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
}

.sharepoint-container h1, h2 {
  color: #333;
  margin-top: 20px;
}

.sharepoint-container ul {
  list-style-type: none;
  padding-left: 20px;
}

.sharepoint-container ul li::before {
  content: "•";
  color: #666;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.sharepoint-container li {
  margin-bottom: 10px;
}

.sharepoint-container strong {
  color: #666;
}

.powerapps-container ul li::before {
  content: "•";
  color: #666;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.office365-container ul li::before {
  content: "•";
  color: #666;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.container_c {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: white;
  padding: 5%;
}

.section_c {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  width: 100%;
  max-width: 1200px;
}

.section_c.reversed_c {
  flex-direction: row-reverse;
}

.image_c {
  width: 40%;
  height: auto;
}

.points_c {
  width: 50%;
  text-align: left;
}

.title_c {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.point-item_c {
  margin-bottom: 10px;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .section_c,
  .section_c.reversed_c {
    flex-direction: column;
  }
  .image_c{
    width: 100%;
  }
  .points_c{
    width: 100%;
  }
}

.video-section {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f5f5f5;
  width: 100%;
}

.video-card {
  width: 80%;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 20px;
}

.video {
  width: 100%;
  border-radius: 15px;
}
