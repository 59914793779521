  /* Global Styles */
  ul {
    list-style-type: none;
    padding: 0px;
  }
  
  ul li {
    padding: 10px 15px;
    font-weight: 500;
    cursor: pointer;
  }

  li a{
    color: white;
    text-decoration: none;
  }
  
  /* Navbar */
  .navbar {
    display: flex;
    position: sticky;
    top: 0px;
    z-index: 12;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    background-color: #ffffff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 10px 30px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05), 0 3px 6px rgba(0, 0, 0, 0.05);
  }
  
  .menu_list {
    display: flex;
    margin: 0px;
  }
  
  /* Mobile Navbar */
  .menu_icon {
    position: absolute;
    top: 16px;
    right: 20px;
    cursor: pointer;
    margin: 10px;
    z-index: 99;
  }
  
  .mobileNav_menu_container {
    position: relative;
  }

  .mobileNav_open_bg.mobileNav_container{
    background-color: rgb(26, 26, 26);
  }

  .mobileNav_open_bg .menu_icon{
    color: white;
  }
  
  .mobileNav_menu_list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    width: 100%;
    margin: auto;
    background: rgb(26, 26, 26);
    border-radius: 4px;
    color: white;
    transition: ease all 0.4s;
  }
  
  .mobileNav_container {
    display: none;
    background-color: #ffffff;
  }
  
  .mobileNav_closed {
    top: -500px;
  }
  
  .mobileNav_open {
    border-top: white 1px solid;
    border-radius: 0px;
    top: -2px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }  
  /* Media Queries */
  @media only screen and (max-width: 600px) {
    /* Navbar Media Queries */
    .navbar {
      display: none;
    }
    /* Mobile Navbar Media Queries */
    .mobileNav_container {
      display: flex;
      flex-direction: column;
      position: sticky;
      top: 0;
      z-index: 11;
    }
  }
  